.cell-class-3 {
    border-color: black; 
    border-bottom-width: .01em; 
    border-left-width: .01em; 
    border-right-width: .01em; 
    border-top-width: 0; 
    border-style: solid;
    margin-bottom : -1px;
}

.cell-class-1 {
    border-color: black; 
    border-top-width: .01em; 
    border-left-width: .01em; 
    border-right-width: .01em; 
    border-bottom-width: 0; 
    border-style: solid;
    margin-bottom : -1px;
}

.cell-class-0 {
    border-color: black; 
    border-width: .01em; 
    border-style: solid;
    margin-bottom : -1px;
}

.cell-class-2 {
    border-color: black; 
    border-top-width: 0; 
    border-left-width: .01em; 
    border-right-width: .01em; 
    border-bottom-width: 0; 
    border-style: solid;
    margin-bottom : -1px;
}